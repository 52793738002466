export default [
    { name: 'menu-link', component: () => import('@/components/MenuLink.vue') },
    { name: 'filter-bar', component: () => import('@/components/FilterBar.vue') },
    { name: 'action-bar', component: () => import('@/components/ActionBar.vue') },
    { name: 'side-bar', component: () => import('@/components/SideBar.vue') },
    { name: 'top-bar', component: () => import('@/components/TopBar.vue') },
    { name: 'v-select', component: () => import('vue-select') },
    { name: 'base-form', component: () => import('@/components/Form.vue') },
    { name: 'base-table', component: () => import('@/components/Table.vue') },
    { name: 'div-table', component: () => import('@/components/DivTable.vue') },
    { name: 'div-filter', component: () => import('@/components/DivFilter.vue') },
    { name: 'div-paginate', component: () => import('@/components/paginate.vue') },
    { name: 'Login', component: () => import('@/components/Login.vue') },
    { name: 'modal-info-order', component: () => import('@/views/orders/modalInfos.vue') },
    { name: 'modal-info-expo', component: () => import('@/views/expeditions/modalInfos.vue') },
    { name: 'modal-info-shipp', component: () => import('@/views/shippings/modalInfos.vue') },
    { name: 'modal-info-shipp-print', component: () => import('@/views/shippings/modalInfosPrint.vue') },
    { name: 'modal-info-sourcing', component: () => import('@/views/sourcings/modalInfos.vue') },
    { name: 'modal-info-pay-print', component: () => import('@/views/payments/modalInfosPrint.vue') },
    { name: 'modal-export-excel-orders', component: () => import('@/views/orders/dataExcel.vue') },
    { name: 'modal-export-excel-shippings', component: () => import('@/views/shippings/dataExcel.vue') },
    { name: 'chart-expeditions', component: () => import('@/views/expeditions/chart.vue') },
    { name: 'chart-products', component: () => import('@/views/products/chart.vue') },
    { name: 'expandable-image', component: () => import('@/components/ExpandableImage.vue') },
    { name: 'invoice-payment', component: () => import('@/views/payments/view.vue') },
]