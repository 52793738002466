const ID_TOKEN_KEY = "id_token";

//const test="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmNhNjFhNDVjZTc0NmExZjg0MGJlMTciLCJ1c2VybmFtZSI6InN1cGVyQWRtaW4iLCJpYXQiOjE2MDcwOTg4MDgsImV4cCI6MTYwNzcwMzYwOH0.8-OkWyf3FpG40ZBgiEVhc8taFztUOkmkrIbJQoiD7Tg"
//const test="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmM4MGQ4NDc4ZmEzODEwMGZhNzVhNTUiLCJ1c2VybmFtZSI6ImFieWFkZUFkbWluIiwiaWF0IjoxNjA3MTY3Mzg2LCJleHAiOjE2MDc3NzIxODZ9.mkOdLxLUmnhYWc8DGzLK0BKqwE_svQoU2kyw-3asGHA"
const test="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmNiN2IzOGQ1NmI5ZjI1YjdhN2Q2MWIiLCJ1c2VybmFtZSI6ImFieWFkZUFkbWluTTEiLCJpYXQiOjE2MDcxNzA4OTEsImV4cCI6MTYwNzc3NTY5MX0.p-gbDiqRXwzjK_Im3ItWFYe0wts-ItiAnmzVmXCcWrc"


export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

    
export const verifyToken = () => {
  //console.log(getToken())
  if(getToken())
        return true
  return false
};


export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};


export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken ,verifyToken};
