import server from '../../plugins/server'
import $f from '../../plugins/functions'
import countries from '../../assets/data/countries.json'
export default {
    namespaced: true,
    state() {
        return {
           warhouseSelected: localStorage.getItem('countryW') || 'CI',
           sous_warhouseSelected: localStorage.getItem('sous_countryW') || 'all',
           changeW:false,
           changeW_sous:false,
           user:{},
           warhouses:[],
           options:countries,
           warehouses:[],
           warehousesUser:[],
           warehousesSeller:[]
        };
    },

    mutations: {
        changeWrahouse: function(state, payload) {
            if(payload.type=='principal'){
                state.warhouseSelected=payload.warhouse;
                state.changeW=true;
            }else{
                state.sous_warhouseSelected=payload.warhouse;
                state.changeW_sous=true;
            }
            console.log('event target value_______________',payload.warhouse)
        },
        getWarehouses: function(state, payload) {
            state.user=payload.user;
            console.log('event target value user_______________',payload.user)
        },
    },
    actions: {
        changeWrahouse(context, payload) {
            context.commit('changeWrahouse', payload);
        },
        async getwharhouseId({getters},payload){
            const resW = await server.get("warehouses", {country: payload.country}); 
            if(resW.content) return  resW.content;
        },
        async getCurrency({getters,state},payload){
            // console.log('getters currency------',payload)
            // console.log('war currency------',state)
             console.log('war currency------',state)
           if(state.warehouses && state.warhouseSelected) {
            const dataW=state.warehouses.find(o => o.code === state.warhouseSelected);
            if(dataW) return dataW.currency; 
           }
            // const resW = await server.get("warehouses", { country: payload.country}); 
            // if(resW.content) return  resW.content.currency;
        },
        async getwharhouseSeller(){
            const resW = await server.get("products", { allWarhouseSeller: true}); 
            return resW.content;
        },
        async searchUser({commit}, payload){
            if(payload.type!='zone'){
                const typeuser=payload.type[0].toUpperCase() + payload.type.slice(1);
                let filter = {
                    type: typeuser,
                    limit:10,
                    countries: payload.warhouse,
                    name: payload.search,
                };  
                if(typeuser === 'Courier') filter.searchByfullName=true;
                console.log('filter w',filter)
                const res= await server.find("users",filter);   
                console.log('res w',res.content)
                if (res.content) return res.content.results;
                else return [];
           }else{
                const filter = {
                    limit:10,
                    country: payload.warhouse,
                    name: payload.search,
                };  
                console.log('filter w',filter)
                const res= await server.find("zones",filter);   
                console.log('res w',res.content)
                if (res.content.results) return res.content.results;
                else return [];
           }
        },
        async searchProducts({commit}, payload){
            const filter = {
                limit:10,
                seller: payload.seller,
                'details.warehouse': payload.warhouse,
                name: payload.search,
            };  
            console.log('filter w',filter)
            const res= await server.find("products",filter);   
            console.log('res ppppp',res)
            if (res.content && res.content.results) return res.content.results;
            else return [];
        },
        
        async getWarehouses({commit, state},payload){
            commit('getWarehouses', payload);
            const filter = {limit:10};
            state.warehouses=[];
            state.warehousesUser=[];
            console.log('stat user -------',state.user)
          //  const resUser = await server.me("users");
            if (state.user){
                if(state.user.countries) {
                    state.warehousesUser = state.user.countries;
                    // if(state.user.type=="Seller"){
                    //     const resWExpe = await server.get("expeditions", { allWarhouseSeller: true}); 
                    //     if(resWExpe.content.length>0){
                    //         for(let i in resWExpe.content){
                    //             if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
                    //         }
                    //     }
                    // }
                    for(let i in state.warehousesUser){
                        if(!state.warehousesUser[i].name && !state.warehousesUser[i].code)  state.warehousesUser[i]={name: $f.getCountryNameByCode(state.options,state.warehousesUser[i]),code:state.warehousesUser[i],currency:$f.getCurrencyByCode(state.options,state.warehousesUser[i])}
                    }
                    
                }
            } 

            if(state.warehousesUser.length>0){
                state.warehouses= state.warehousesUser;
            }else{
              const res = await server.search("warehouses",filter);
              if (res.content.results) {
                  let data = res.content.results;
                  for(let i in data){
                    state.warehouses.push({name:$f.getCountryNameByCode(state.options,data[i].country),code:data[i].country,currency:$f.getCurrencyByCode(state.options,data[i].country)})
                  }
              } else state.warehouses = [];
          }
          return  state.warehouses;
        }
    },
    getters: {
        warhouseSelected: (state) => async (data) => {
       // console.log('pooooooooooooooooooooo',data.user)
        //console.log('pooooooooooooooooooooo',state.warhouseSelected)
           if(!state.changeW){
                //const resW = await server.get("products", { allWarhouseSeller: true}); 
               // const resUser = await server.me("users");
                //check if user has countries warhouse
                if(data.user && data.user.countries && data.user.countries.length>0) {
                    // if(localStorage.getItem('countryW')){
                    //     if(resUser.content.countries.indexOf(localStorage.getItem('countryW'))===-1){
                    //         //return resUser.content.countries[0];
                    //     }
                    // }    
                    //console.log('oook');
                    if(data.user.countries.length==1) {
                        state.warhouseSelected=data.user.countries[0].code||data.user.countries[0];
                        localStorage.setItem('countryW', state.warhouseSelected);
                    }
                    return localStorage.getItem('countryW') || data.user.countries[0].code||data.user.countries[0];
                }
           }
           
           return state.warhouseSelected;
        },
        sous_warhouseSelected: (state) => async (data) => {
            //console.log('-------------------sous_warhouseSelected---------------')
            if(!state.changeW_sous){

                if(data.user && ['Seller','StockManager','Administrateur'].indexOf(data.user.type) == -1){
                    if(data.user && data.user.sous_warehouses && data.user.sous_warehouses.length>0) {   
                        // if(data.user.sous_warehouses.length==1) {
                        //     state.sous_warhouseSelected=data.user.sous_warehouses[0]._id.toString();
                        //     localStorage.setItem('sous_countryW', state.sous_warhouseSelected);
                        // }else{
                            //console.log('multiple ware----',localStorage.getItem('countryW'))
                           // console.log('data.user.warehouses ware----',data.user.warehouses)
                            //console.log('localStorage countryW----',localStorage.getItem('countryW'))
                            if(localStorage.getItem('sous_countryW')){
                                const dataW=data.user.sous_warehouses.find(o => o._id.toString() === localStorage.getItem('sous_countryW'));
                               // console.log('dataW----',dataW)
                                if(!dataW && localStorage.getItem('sous_countryW') != 'all') {
                                   localStorage.setItem('sous_countryW', data.user.sous_warehouses[0]._id.toString());
                                }
                            }else{
                                localStorage.setItem('sous_countryW', 'all'||data.user.sous_warehouses[0]._id.toString());
                                //reduxStore.dispatch("actionupdate",{update:true,pr_version:localStorage.getItem('pr-version')});
                            }
                       // }
                        return localStorage.getItem('sous_countryW') || 'all' || data.user.sous_warehouses[0]._id.toString();
                    }
                }else state.sous_warhouseSelected= null;

            }
            
            return state.sous_warhouseSelected;
         },
        // async warehouses(state){
        //     //console.log('pooooooooooooooooooooo',data.user)
        //     const filter = {limit:10};
        //     state.warehousesUser=[];
        //     state.warehouses=[];
        //     const resUser = await server.me("users");
        //     if (resUser.content){
        //         if(resUser.content.countries) {
        //             state.warehousesUser = resUser.content.countries;
        //             if(resUser.content.type=="Seller"){
        //                 const resWExpe = await server.get("expeditions", { allWarhouseSeller: true}); 
        //                 if(resWExpe.content.length>0){
        //                     for(let i in resWExpe.content){
        //                         if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
        //                     }
        //                 }
        //             }
        //             for(let i in state.warehousesUser){
        //                 state.warehousesUser[i]={name: $f.getCountryNameByCode(state.options,state.warehousesUser[i]),code:state.warehousesUser[i]}
        //             }
                    
        //         }
        //     } 

        //     if(state.warehousesUser.length>0){
        //         state.warehouses= state.warehousesUser;
        //     }else{
        //       const res = await server.search("warehouses",filter);
        //       if (res.content.results) {
        //           let data = res.content.results;
        //           for(let i in data){
        //             state.warehouses.push({name:$f.getCountryNameByCode(state.options,data[i].country),code:data[i].country})
        //           }
        //       } else state.warehouses = [];
        //   }
        //   console.log('warehouses__________________',state.warehouses)
        //   return  state.warehouses;
        // },
        warehouses: (state) => async (data) => {
           // console.log('ttttttttttttttttttttttttttt',data)
            const filter = {limit:10};
            state.warehousesUser=[];
            state.warehouses=[];
           // const resUser = await server.me("users");
            if (data.user){
                if(data.user.countries) {
                    console.log('data.user.countries',data.user.countries)
                    state.warehousesUser = data.user.countries;
                    // if(resUser.content.type=="Seller"){
                    //     const resWExpe = await server.get("expeditions", { allWarhouseSeller: true}); 
                    //     if(resWExpe.content.length>0){
                    //         for(let i in resWExpe.content){
                    //             if(state.warehousesUser.indexOf(resWExpe.content[i]) === -1) state.warehousesUser.push(resWExpe.content[i])
                    //         }
                    //     }
                    // }
                    //console.log('state.warehousesUser',state.warehousesUser)
                    for(let i in state.warehousesUser){
                        if(!state.warehousesUser[i].name && !state.warehousesUser[i].code) state.warehousesUser[i]={name: $f.getCountryNameByCode(state.options,state.warehousesUser[i]),code:state.warehousesUser[i],currency:$f.getCurrencyByCode(state.options,state.warehousesUser[i])}
                    }
                    
                }
            } 

            if(state.warehousesUser.length>0){
                state.warehouses= state.warehousesUser;
            }else{
              const res = await server.search("warehouses",filter);
              if (res.content.results) {
                  let data = res.content.results;
                  for(let i in data){
                     state.warehouses.push({name:$f.getCountryNameByCode(state.options,data[i].country),code:data[i].country,currency:$f.getCurrencyByCode(state.options,data[i].country)})
                  }
              } else state.warehouses = [];
          }
         // console.log('warehouses__________________',state.warehouses)
          return  state.warehouses;
        }
       
    }
};