export default {
    color: "text-blue-900",
    link: "text-white  hover:text-blue-800",

    topBar: "bg-white",
    sideBar: "bg-green-600  text-white",
    textPrimary: "text-green-700    hover:text-blue-800",
    logoBar: "text-blue-900",

    pButton: "bg-blue-800 text-white hover:bg-gray-500 ",
    sButton: "bg-gray-500 text-white hover:bg-blue-800  ",
    primary: "text-white bg-gray-500 hover:bg-green-700 ",

    filter: "bg-white text-blue-800 border-blue-800 hover:bg-blue-800",
    required: "text-green-500 ",
    actionTable: "text-black opacity-75   hover:text-green-500 ",
    newColor: "text-green-500  hover:text-green-500  ",
    pButton1: "bg-green-600  text-white hover:bg-gray-500 ",
    primary1: "text-white bg-gray-500 hover:bg-green-600 ",
    white: "text-white",

}

/*

export default {
        color:"text-pink-900",

        topBar:"bg-white",
        sideBar:"bg-pink-800 text-white",
        textPrimary:"text-pink-900 hover:text-pink-00  ",
        logoBar:" text-pink-600",

        pButton:"bg-pink-500 text-white hover:bg-pink-600 ",
        sButton:"text-pink-500 bg-gray-300 hover:bg-pink-500 hover:text-white  ",

        primary:"text-white bg-pink-500 hover:bg-pink-600" ,

        filter:"bg-white text-pink-600 border-pink-600 hover:bg-pink-600"
}
*/