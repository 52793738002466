import Vue from 'vue'
import Vuex from 'vuex'

import wahrhouseModule from './modules/warhouses.js';

Vue.use(Vuex)

export default new Vuex.Store({
  
    state() {
        return {
            open: false,
            loading:false,
            loadingEmail:'pending',
            update:false,
            model:'',
            pr_version:1,
            sous_warehouses:[],
            user:{}
        };
    },
    mutations: {
        actionSidebar(state) {
            state.open = !state.open;
        },
        actionloadingpage: function(state, payload) {
            state.loading = payload.loading;
        },
        actionloadingemail: function(state, payload) {
            state.loadingEmail = payload.loadingEmail;
        },
        actionupdate: function(state, payload) {
            state.update = payload.update;
           if(payload.pr_version) state.pr_version = payload.pr_version;
        },
        modelupdate: function(state, payload) {
            state.model = payload.model;
        },
        actionSousWarehouses: function(state, payload) {
            state.sous_warehouses = payload.sous_warehouses;
        },
        actionSeller: function(state, payload) {
            state.user = payload.user;
        },
    },
    actions: {
        actionSidebar(context) {
            context.commit('actionSidebar');
        },
        actionloadingpage(context, payload) {
            context.commit('actionloadingpage',payload);
        },
        actionloadingemail(context, payload) {
            context.commit('actionloadingemail',payload);
        },
        actionupdate(context, payload) {
           // console.log('payload  actions------',payload)
            context.commit('actionupdate',payload);
        },
        modelupdate(context, payload) {
            console.log('payload  modelupdate------',payload)
            context.commit('modelupdate',payload);
        },
        actionSousWarehouses(context, payload) {
            context.commit('actionSousWarehouses',payload);
        },
        actionSeller(context, payload) {
            context.commit('actionSeller',payload);
        },
    },
    getters: {
        ShowSidebar(state) {
            return state.open;
        },
        loadingpage(state) {
            return state.loading;
        },
        loadingemail(state) {
            return state.loadingEmail;
        },
        checkUpdate(state) {
            return state.update;
        },
        resize() {
            if (window.innerWidth < 960) return true
            else return false
        },
    },
    modules:{
        wharhouse: wahrhouseModule,
    }

})
